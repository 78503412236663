


























import Vue from 'vue';
import AccountSetting from '@/blueprint/components/landlord/settings/Setting-Account.vue';
import AccountNotify from '@/blueprint/components/landlord/settings/Setting-Notification.vue';
import ApplicationSettings from '@/blueprint/components/landlord/settings/Setting-Application.vue';
import CloseAccount from '@/blueprint/components/landlord/settings/Setting-Close-Account.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { LandlordState } from '@/store/landlord/state';
import { User } from '@lordly/models2/interfaces/models/User';

export default Vue.extend({
  name: 'Settings-Page',
  components: {
    'account-settings': AccountSetting,
    'account-notify': AccountNotify,
    'app-settings': ApplicationSettings,
    'close-account': CloseAccount,
  },
  data () {
    return {};
  },
  computed: {
    user (): LandlordState['user'] {
      return (this.$store.state.landlord as LandlordState).user;
    },
  },
  methods: {
    async update (body: Partial<User['profile']>) {
      // Generate query
      const query: GQLTagRequestObject = gql`
        mutation ($id: String!, $partition: UserType!, $user: IUserProfile!) {
          UpdateUser(
            input: {
              id: $id,
              partition: $partition,
              user: $user
            }
          ) {
            profile {
              firstname
              surname
              image {
                id
                url
              }
              description
              __typename
              ... on UserProfileExtended {
                contact {
                  email
                  mobile
                }
                notify
              }
            }
          }
        }
      `;
      // Generate payload
      const payload: API.UpdateUserInput = {
        id: this.user.id,
        partition: 'Landlord',
        user: body,
      };
      // Send Query
      try {
        const response: Partial<User> = await this.$gql.Mutation('UpdateUser', query, payload);
        this.updateVuexStore('user.image', response.profile!.image);
        this.updateVuexStore('user.firstname', response.profile!.firstname);
        this.updateVuexStore('user.surname', response.profile!.surname);
        this.updateVuexStore('user.description', response.profile!.description);
        this.updateVuexStore('user.notify', response.profile!.notify);
        this.updateVuexStore('user.contact.email', response.profile!.contact.email);
        this.updateVuexStore('user.contact.mobile', response.profile!.contact.mobile);
      } catch (e) {
        console.error(e);
      }
    },
    updateVuexStore (key: string, value: any ) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
