




















import Vue from 'vue';
import SettingCard from '@/blueprint/components/landlord/settings/abstraction/Setting-Card.vue';
import { UpdateApp } from '@/assets/mixins';

export default Vue.extend({
  name: 'Setting-Application-Component',
  components: {
    'setting-card': SettingCard,
  },
  methods: {
    updateApplication () {
      UpdateApp();
    },
  },
});
