
























































import Vue from 'vue';
import { ValidateFields } from '@/assets/mixins';
import SettingCard from '@/blueprint/components/landlord/settings/abstraction/Setting-Card.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { LandlordState } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Setting-Close-Account-Component',
  components: {
    'setting-card': SettingCard,
  },
  data: () => {
    return {
      invoked: false,
      message: 'Close Account',
      timeout: -1,
      showPopup: false,
      form: {
        feedback: '',
      },
      closingAccount: false,
    };
  },
  methods: {
    invokeCloseAccount () {
      if (this.message === 'Close Account') {
        this.message = 'Are you sure?';
        this.resetMessage();
      } else {
        if (this.message === 'Are you sure?') {
          // Show feedback modal
          this.showPopup = true;
        }
        this.resetMessage();
      }
    },
    resetMessage () {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.message = 'Close Account';
      }, 2500);
    },
    async close () {
      // Validate
      const hasError: boolean = !ValidateFields(this, ['feedback']);
      // Form valid
      if (!hasError) {
        this.closingAccount = true;
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $reason: String!) {
            ObsoleteUser (
              input: {
                id: $id,
                reason: $reason
              }
            )
          }
        `;
        // Get landlord state
        const landlordState: LandlordState = this.$store.state.landlord as LandlordState;
        // Create payload
        const payload: API.ObsoleteUserInput = {
          id: landlordState.user.id,
          reason: this.form.feedback,
        };
        // Execute query
        try {
          const response: boolean = await this.$gql.Mutation('ObsoleteUser', query, payload) as boolean;
          // Notify out outcome
          this.$q.notify({
            message: `${response ? 'Successfully' : 'Unsuccessfully'} closed your Lordly account.${ !response ? ' Contact support for further help!' : ''}`,
            color: (response ? 'green' : 'red'),
            timeout: 3000,
          });
          // Logout (Next tick)
          setTimeout(() => {
            this.$auth0.logout(this);
          }, 3000);
        } catch (e) {
          console.error(e);
        } finally {
          // Stop loading
          this.closingAccount = false;
          // Close popup
          this.showPopup = false;
        }
      }
    },
  },
});
