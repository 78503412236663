
































import Vue from 'vue';
import SettingCard from '@/blueprint/components/landlord/settings/abstraction/Setting-Card.vue';
import { debounce } from 'quasar';

import { User } from '@lordly/models2/interfaces/models/User';
import { LandlordState } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Setting-Notification-Component',
  components: {
    'setting-card': SettingCard,
  },
  data: () => {
    return {
      form: {
        notify: false,
      },
      debounceUpdate: () => { return; },
      booleanOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    };
  },
  computed: {
    user (): LandlordState['user'] {
      return (this.$store.state.landlord as LandlordState).user;
    },
  },
  created () {
    // Sync
    this.syncUserForm();
    // Setup debounce update
    this.debounceUpdate = debounce(() => {
      this.update();
    }, 750);
  },
  methods: {
    update () {
      // Create request body
      const body: Partial<User['profile']> = {
        notify: this.form.notify,
      };
      // Emit the update
      this.$emit('update', body);
    },
    syncUserForm () {
      this.form.notify = this.user.notify;
    },
  },
});
