













































































































































import Vue from 'vue';
import SettingCard from '@/blueprint/components/landlord/settings/abstraction/Setting-Card.vue';
import SettingProfileImage from '@/blueprint/components/landlord/settings/abstraction/Setting-Profile-Image.vue';
import { ValidateFields } from '@/assets/mixins';

import { email } from 'vuelidate/lib/validators';
import { date, debounce } from 'quasar';

import { LandlordState } from '@/store/landlord/state';
import { RootStore } from '@/store';
import { User } from '@lordly/models2/interfaces/models/User';
import { Blob } from '@lordly/models2/interfaces';

export default Vue.extend({
  name: 'Setting-Account-Component',
  components: {
    'setting-card': SettingCard,
    'setting-profile-image': SettingProfileImage,
  },
  data: () => {
    return {
      form: {
        image: [] as Blob[],
        firstname: '',
        surname: '',
        contactEmail: '',
        contactMobile: '',
        description: '',
      },
      debounceUpdate: () => { return ; },
    };
  },
  validations: {
    form: {
      contactEmail: {
        email,
      },
    },
  },
  computed: {
    fullName (): string {
      return this.user.firstname + ' ' + this.user.surname;
    },
    joinedOn (): string {
      return date.formatDate(new Date(this.user.createdOn), 'Do MMMM YYYY');
    },
    profileImage (): string {
      if (this.user.image && this.user.image.length > 0) {
        return this.user.image[0].url;
      }
      return (this.$store.state as RootStore).avatar;
    },
    user (): LandlordState['user'] {
      return (this.$store.state.landlord as LandlordState).user;
    },
    overrideImage: {
      get (): string {
        return this.user.overrideImage;
      },
      set (value: string) {
        this.$vuex.MutateStore(this, 'landlord', 'user.overrideImage', value);
      },
    },
  },
  created () {
    this.syncUserForm();
    // Setup debounce update
    this.debounceUpdate = debounce(() => {
      this.update();
    }, 2500);
  },
  methods: {
    newProfileImage (blob: Blob) {
      this.form.image = [blob];
      this.overrideImage = blob.url;
      if (blob && blob.id === 'cleared') {
        this.overrideImage = '';
      }
      this.update();
    },
    syncUserForm () {
      this.form.firstname = this.user.firstname;
      this.form.surname = this.user.surname;
      this.form.image = this.user.image;
      this.form.contactEmail = this.user.contact.email || this.user.email;
      this.form.contactMobile = this.user.contact.mobile || '';
      this.form.description = this.user.description || '';
    },
    async update () {
      // Validate
      let hasError: boolean = !this.validateFields(['firstname', 'surname', 'mobile', 'email', 'description']);
      if (!hasError) {
        // Create request body
        const body: Partial<User['profile']> = {
          image: this.form.image,
          firstname: this.form.firstname,
          surname: this.form.surname,
          contact: {
            email: this.form.contactEmail,
            mobile: this.form.contactMobile,
            other: [],
          },
          description: this.form.description,
        };
        // Emit the update
        this.$emit('update', body);
      }
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
  },
});
