




























import Vue from 'vue';

export default Vue.extend({
  name: 'Setting-Card-Component',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    actionable: {
      type: Boolean,
      default: false,
      required: false,
    },
    btnIcon: {
      type: String,
      default: '',
      required: false,
    },
    btnToolTip: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => {
    return {
      showAlt: false,
    };
  },
  methods: {
    toggleAlt () {
      this.showAlt = !this.showAlt;
      this.$emit('toggleAltView', this.showAlt);
    },
  },
});
