var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-card',{attrs:{"title":"Account Settings","actionable":"","btnIcon":"mdi-pencil","showEditBtn":"","btnState":"false"},on:{"toggleAltView":function($event){return _vm.syncUserForm()}}},[_c('template',{slot:"main"},[_c('div',{staticClass:"row loadable"},[_c('div',{staticClass:"col-12 spaced"},[_c('label',[_vm._v("Profile Image:")]),_c('div',{staticClass:"profile-image"},[_c('img',{attrs:{"src":_vm.profileImage,"alt":"Profile Image"}})])]),_c('div',{staticClass:"col-12 col-sm-6 spaced"},[_c('label',[_vm._v("Name:")]),_c('p',[_vm._v(_vm._s(_vm.fullName))])]),_c('div',{staticClass:"col-xs-12 col-sm-6 spaced"},[_c('label',[_vm._v("Contact Mobile:")]),_c('p',[_vm._v(_vm._s(_vm.user.contact.mobile ? _vm.user.contact.mobile : ''))])]),_c('div',{staticClass:"col-xs-12 col-sm-12 spaced"},[_c('label',[_vm._v("Contact Email:")]),_c('p',[_vm._v(_vm._s(_vm.user.contact.email ? _vm.user.contact.email : _vm.user.email))])]),(_vm.user.description)?_c('div',{staticClass:"col-xs-12 col-sm-12 spaced"},[_c('label',[_vm._v("A bit about yourself:")]),_c('p',[_vm._v(_vm._s(_vm.user.description))])]):_vm._e(),_c('div',{staticClass:"col-12 spaced"},[_c('label',[_vm._v("Member Since:")]),_c('p',[_vm._v(_vm._s(_vm.joinedOn))])])])]),_c('template',{slot:"alt"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"user-input no-pad col-xs-12 spaced"},[_c('label',[_vm._v("Profile Image:")]),_c('setting-profile-image',{staticStyle:{"margin-bottom":"1rem"},attrs:{"overrideImage":_vm.form.base64OverrideImage,"profileImage":_vm.profileImage,"base64OverrideImage":_vm.overrideImage},on:{"newBlob":_vm.newProfileImage,"newbase64OverrideImage":function (img) { _vm.overrideImage = img}}})],1),_c('div',{staticClass:"user-input col-xs-12 col-lg-6"},[_c('label',[_vm._v("Firstname:")]),_c('q-input',{ref:"firstname",attrs:{"outlined":"","debounce":"500","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length <= 100 || 'Please use less than 100 characters at maximum'; } ]},on:{"input":_vm.debounceUpdate},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('div',{staticClass:"user-input col-xs-12 col-lg-6"},[_c('label',[_vm._v("Surname:")]),_c('q-input',{ref:"surname",attrs:{"outlined":"","debounce":"500","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length <= 100 || 'Please use less than 100 characters at maximum'; } ]},on:{"input":_vm.debounceUpdate},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1),_c('div',{staticClass:"user-input col-xs-12 col-lg-12"},[_c('label',[_vm._v("Contact Mobile:")]),_c('q-input',{ref:"mobile",attrs:{"outlined":"","lazy-rules":"","debounce":"500","rules":[ function (val) { return val.length <= 100 || 'Please use less than 100 characters at maximum'; } ]},on:{"input":_vm.debounceUpdate},model:{value:(_vm.form.contactMobile),callback:function ($$v) {_vm.$set(_vm.form, "contactMobile", $$v)},expression:"form.contactMobile"}})],1),_c('div',{staticClass:"user-input col-xs-12 col-lg-12"},[_c('label',[_vm._v("Contact Email:")]),_c('q-input',{ref:"email",attrs:{"outlined":"","debounce":"500","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length <= 100 || 'Please use less than 100 characters are maximum'; },
            function (val) { return !_vm.$v.form.contactEmail.$error || 'Please enter a valid email'; }
          ]},on:{"input":_vm.debounceUpdate},model:{value:(_vm.form.contactEmail),callback:function ($$v) {_vm.$set(_vm.form, "contactEmail", $$v)},expression:"form.contactEmail"}})],1),_c('div',{staticClass:"user-input col-xs-12 col-lg-12"},[_c('label',[_vm._v("A bit about yourself:")]),_c('q-input',{ref:"description",attrs:{"type":"textarea","outlined":"","debounce":"500","lazy-rules":"","rules":[ function (val) { return val.length <= 1000 || 'Please use less than 1000 characters at maximum'; }]},on:{"input":_vm.debounceUpdate},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }