




















import Vue from 'vue';
import DeleteButton from '@/blueprint/components/ui/DeleteButton.vue';
import { LandlordState } from '@/store/landlord/state';
import { RootStore } from '@/store';

export default Vue.extend({
  name: 'Setting-Profile-Image-Component',
  components: {
    'delete-btn': DeleteButton,
  },
  props: {
    base64OverrideImage: {
      type: String,
      default: '',
      required: false,
    },
    overrideImage: {
      type: String,
      default: '',
      required: false,
    },
    profileImage: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => {
    return {
      deleteInProgress: false,
    };
  },
  methods: {
    submitNewProfileImage (e: any) {
      if (e && e.target && e.target.files && e.target.files[0]) {
        const file: File = e.target.files[0] as File;
        // Check if image is of type jpg, jpeg or png
        if (/\.(jpe?g|png)$/i.test(file.name)) {
          // Get base64image
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = (e) => {
            this.$emit('newbase64OverrideImage', reader.result);
          };
          // Upload
          this.$blob.Create('user', (this.$store.state.landlord as LandlordState).user.id, null, file, 5).then(
            (blobs) => {
              if (blobs && blobs.length > 0) {
                this.$emit('newBlob', blobs[0]);
              }
            },
          ).catch(
            (err) => {
              if (err.indexOf('File too large')) {
                this.$q.notify({
                  position: 'bottom',
                  message: 'File: ' + file.name + ' is too large.',
                  timeout: 5000,
                  color: 'red',
                  textColor: 'white',
                });
              } else {
                console.error(err);
              }
            },
          );
        }
      }
    },
    deleteExistingProfileImage () {
      // Disable Button
      this.deleteInProgress = true;
      // Extract blobID from URL
      const urlParts: string[] = this.profileImage.split('/user/');
      if (urlParts.length === 2) {
        // Get blobID
        const blobId: string = urlParts[1];
        // Delete
        this.$blob.Delete('user', blobId, blobId, this.profileImage).then(() => {
          // Enable button
          this.deleteInProgress = false;
          this.$emit('newBlob', { id: 'cleared', url: (this.$store.state as RootStore).avatar });
        }).catch((err) => {
          this.deleteInProgress = false;
          console.error(err);
        });
      } else {
        console.warn('urlParts not equal to 2');
        this.deleteInProgress = false;
      }
    },
  },
});
